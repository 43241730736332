<template>
  <div>
    <!-- <span class="header-post">Write your mind out!</span> -->
    <b-form @submit="submit" id="creativity-form">
      <b-card class="m-lg-4 m-2">
        <b-card-header header-bg-variant="light">
          <div role="group">
            <b-row>
              <b-col sm="1" class="mt-1"
                ><label for="input-live">Title:</label></b-col
              >
              <b-col sm="10">
                <b-form-input
                  id="input-live"
                  v-model="title"
                  placeholder="Title of your creativity"
                  trim
                  :disabled="isContnuation"
                  required
                ></b-form-input
              ></b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="3">
                <b-form-checkbox
                  id="checkbox-1"
                  class="mt-2"
                  style="display: inline-block"
                  v-model="isContnuation"
                >
                  Is this a continuation of another story? </b-form-checkbox
                >&nbsp;
                <b-icon-info-circle-fill
                  @click="showhint = !showhint"
                  variant="primary"
                ></b-icon-info-circle-fill
              ></b-col>
              <b-col sm="2" v-if="isContnuation">
                <label for="language-select" class="mt-2"
                  >Select the story:</label
                ></b-col
              >
              <b-col sm="6" v-if="isContnuation"
                ><b-form-select
                  id="language-select"
                  v-model="writingPartId"
                  @input="partSelection"
                  :disabled="myWrittingsOptions.length === 0"
                  :options="myWrittingsOptions"
                ></b-form-select></b-col
            ></b-row>
            <p class="checkbox-hint" v-if="showhint">
              <span>
                You can break up your writings into smaller parts. For
                continuation, click the above check box and select the previous
                part of the writing</span
              >
            </p>
          </div>
        </b-card-header>

        <b-card-body style="padding: 0">
          <b-form-textarea
            v-model="content"
            id="textarea-rows"
            placeholder="Open up your mind here!!"
            rows="10"
            max-rows="20"
            required
          ></b-form-textarea>
        </b-card-body>

        <b-card-footer>
          <div>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="checkbox-2" class="mt-2"
                  >Choose Category:</label
                ></b-col
              >
              <b-col sm="2">
                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  v-model="isStory"
                  :disabled="isContnuation"
                >
                  This is a Story
                </b-form-checkbox></b-col
              >
              <b-col sm="3"
                ><b-form-select
                  id="category-select"
                  v-model="category"
                  :options="options"
                  required
                  :disabled="isContnuation"
                ></b-form-select
              ></b-col>
              <b-col sm="2">
                <label for="language-select" class="mt-2"
                  >Choose Language:</label
                ></b-col
              >
              <b-col sm="3"
                ><b-form-select
                  id="language-select"
                  v-model="language"
                  :options="languages"
                  required
                  :disabled="isContnuation"
                ></b-form-select></b-col
            ></b-row>
          </div>
        </b-card-footer>
      </b-card>
      <b-button
        variant="outline-info"
        class="mx-2"
        @click="savePost()"
        v-if="currentArticle.isSaved || id === ''"
        :disabled="!this.title && !this.content"
        >Save for Later</b-button
      >
      <b-button v-if="isLoading" variant="info" disabled>
        <b-spinner small></b-spinner>
        Please Wait...
      </b-button>
      <b-button v-else variant="info" class="mx-2" type="submit"
        >Submit</b-button
      >
    </b-form>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "../router/EventBus";
import {
  LANGUAGES,
  CATEGORY_GENERAL,
  CATEGORY_STORY,
} from "../constants/general";
import {
  BForm,
  BCard,
  BCardBody,
  BCardHeader,
  BCardFooter,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
  BButton,
  BIconInfoCircleFill,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "my-creativity",

  components: {
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BCardFooter,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    BButton,
    BIconInfoCircleFill,
    BSpinner,
  },

  data() {
    return {
      id: "",
      title: "",
      content: "",
      category: "",
      language: "",
      writingPartId: "",
      savedId: "",
      isStory: false,
      isLoading: false,
      showhint: false,
      generalCategories: CATEGORY_GENERAL,
      storiesCategories: CATEGORY_STORY,
      myWrittings: [],
      myWrittingsOptions: [],
      isContnuation: false,
    };
  },
  computed: {
    ...mapGetters(["userDetails", "currentArticle"]),

    options() {
      return this.isStory ? this.storiesCategories : this.generalCategories;
    },

    languages() {
      return LANGUAGES.filter((item) => item !== "All");
    },
  },

  async mounted() {
    if (this.currentArticle._id) {
      this.id = this.currentArticle._id;
      this.title = this.currentArticle.title;
      this.content = this.currentArticle.content;
      this.category = this.currentArticle.category;
      this.language = this.currentArticle.language;
      this.isStory = this.storiesCategories.includes(
        this.currentArticle.category
      );
    }

    try {
      let writings = await this.fetchArticlesByAuthor({
        authorId: this.userDetails.id,
      });

      writings.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });

      this.myWrittings = [...writings];

      writings.forEach((item) => {
        if (item.isPilot) {
          this.myWrittingsOptions.push({
            value: item._id,
            text: item.title,
          });
        }
      });
    } catch (e) {
      if (e.response.status === 401) {
        this.signOut();
      }
    }

    EventBus.$on("modalOkClicked", () => {
      this.$router.push(`/post/${this.id}`);
    });
  },

  methods: {
    ...mapActions([
      "postArticle",
      "editArticle",
      "notifyAll",
      "saveArticle",
      "fetchArticlesByAuthor",
      "signOut",
    ]),

    ...mapMutations(["setCurrentArticle"]),

    partSelection() {
      let titleObj = this.myWrittings.find(
        (item) => item._id === this.writingPartId
      );

      this.title = `${titleObj.title}: Part - ${titleObj.parts.length + 2}`;
      this.isStory = this.storiesCategories.includes(titleObj.category);
      this.category = titleObj.category;
      this.language = titleObj.language;
    },

    async savePost() {
      if (this.currentArticle.isSaved) {
        this.savedId = this.currentArticle._id;
      }
      let payload = {
        title: this.title,
        content: this.content,
        language: this.language,
        category: this.category,
        articleId: this.savedId,
        ...(this.isContnuation && { parentId: this.writingPartId }),
      };

      try {
        let response = await this.saveArticle({
          id: this.userDetails.id,
          payload,
        });

        this.savedId = response._id;

        this.$bvToast.toast("Your post has been saved successfully!", {
          title: "Saved successfully!",
          autoHideDelay: 2000,
          variant: "warning",
          solid: true,
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
        EventBus.$emit("showCommonModal", {
          title: "Error",
          content: "Error Occurred. Please try again!",
          variant: "danger",
        });
      }
    },

    async submit() {
      event.preventDefault();
      if (!this.id || this.currentArticle.isSaved) {
        let date = new Date();
        let payload = {
          title: this.title,
          time: date,
          content: this.content,
          author: {
            name: this.userDetails.name,
            id: this.userDetails.id,
          },
          language: this.language,
          category: this.category,
          parentId: this.writingPartId,
          isSaved: this.currentArticle.isSaved,
        };

        try {
          this.isLoading = true;
          let response = await this.postArticle({ payload });
          this.id = response._id;

          this.notifyAll({
            payload: {
              message: `posted a new article`,
              userId: this.userDetails.id,
              userName: this.userDetails.name,
              article: this.title,
              articleId: this.id,
              time: date,
              id: this.userDetails.followedBy,
            },
          });

          EventBus.$emit("showCommonModal", {
            title: "Success",
            content: "Posted Successfully",
            isEmit: true,
          });
        } catch (e) {
          if (e.response.status === 401) {
            this.signOut();
          }
          EventBus.$emit("showCommonModal", {
            title: "Error",
            content: "Error Occurred. Please try again!",
            variant: "danger",
          });
        } finally {
          this.isLoading = false;
        }
      } else {
        let payload = {
          title: this.title,
          content: this.content,
          language: this.language,
          category: this.category,
          userId: this.userDetails.id,
        };

        try {
          this.isLoading = true;
          await this.editArticle({
            payload,
            articleId: this.currentArticle._id,
          });
          EventBus.$emit("showCommonModal", {
            title: "Success",
            content: "Edited Successfully",
            isEmit: true,
          });
        } catch (e) {
          if (e.response.status === 401) {
            this.signOut();
          }
          EventBus.$emit("showCommonModal", {
            title: "Error",
            content: "Error Occurred. Please try again!",
            variant: "danger",
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
  },

  beforeDestroy() {
    this.setCurrentArticle({});
  },
};
</script>

<style lang="scss" scoped>
#input-live,
#language-select,
#textarea-rows,
#category-select {
  background-image: none;

  &:focus:required:invalid {
    border: solid 2px red;
  }

  &:focus:required:valid {
    border: solid 2px rgb(33, 224, 7);
  }
}
#textarea-rows {
  min-height: 252px;
  border: none;
  overflow-y: auto !important;
}
.card-body {
  padding: 0.25rem !important;
}
// .header-post {
//   font-family: "Dancing Script", cursive;
//   font-size: 40px;
//   margin-bottom: 30px;
// }
.checkbox-hint {
  font-size: 14px;
  text-align: left;
  margin-left: 1rem;
  color: #7a7979;
}

@media only screen and (max-width: 767px) {
  .header-post {
    font-size: 28px;
  }
}
</style>
